import { Link } from "react-router-dom";

// import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { mobileTheme } from "../../constant";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { MdGroups } from "react-icons/md";
import moment from "moment";
import * as React from 'react';

import "../../../style.css";
import "./RoleCard.scss";
import BasicPopoverRole  from "../Popover/PopoverRoleDelete";
import { history } from "../../../../history";
import DeleteModal from "../DeleteModal/DeleteModal";
import { rolesServices } from "../../../../pages/common/Roles/modules/services";
import {
  alert,
  invalidToken,
} from "../../../../utilities";

export const RoleCard = ({ name, description, date,permissionsData, count,deleteData ,Userid,CloneData,fetchRoleData,setAssignUsers,search}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [deleteModal, setDeleteModal] = React.useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickEdit = () => {
    history.push(`/hr/roles/editRole/${Userid}`)
  }

  const handleClickDelete = () => {
    setDeleteModal(true)
  }

  const deleteModalClose = () => {
    setDeleteModal(false)
  }

  const CloneItem = () => {
    rolesServices.cloneRoles(CloneData)
    .then(response => {
      if (response.data.success === 2) {
        invalidToken(response.data.message)
      }
      else {
        alert.success(response.data.message)
        fetchRoleData()
      }
    });
  }



  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div className={search === true ? "cardSearch" : "RoleCard"}>
      <article>
        <div>
          <div>
            <div>
              <div>{name}</div>
              <div>
                <MdGroups style={{ color: mobileTheme }} />
                <div style={{ fontSize: "12px", color: mobileTheme }}>
                  {count}
                </div>
              </div>
            </div>

            <div
              style={{
                fontSize: "12px",
                fontWeight: "400",
                color: "#787878D9",
              }}
            >
              {description}
            </div>
            <div>
              <div
                style={{
                  color: mobileTheme,
                }}
              >
                Created By: {moment(date).locale("en-US").format("DD MMM YYYY,hh:mm A")}
              </div>
            </div>
          </div>
          {search === true ? "" :
          <BiDotsVerticalRounded 
            onClick={handleClick}
          style={{ color: mobileTheme }} /> }
        </div>
      </article>
      <BasicPopoverRole
          open={open}
          id={id}
          handleClickDelete={handleClickDelete}
          handleClickEdit={handleClickEdit}
          anchorEl={anchorEl}
           permissionsData={permissionsData}
          CloneItem={CloneItem}
          Userid={Userid}
          setAssignUsers={setAssignUsers}
          handleClose={handleClose}
          /> 

        <DeleteModal
            open={deleteModal}
            title={"Are you sure you want to remove role and associated data  with it?"}
            onClose={deleteModalClose}
            handleDelete={() => {deleteData(Userid)}}
          />
    </div>
  );
};
